.plugin-news
    .news
        margin-bottom: 0.8rem
        .title
            font-weight: bold
            padding-left: 3rem
            .fa
                color: color-grey-1
                margin-left: -3rem
                margin-right: 1rem
        .date
            color: color-grey-2
        .summary
            color: color-grey-1
            padding-left: 2rem


.news-detail
    .title
         font-size: 1.5rem
         font-weight: bold
         .fa
             color: color-grey-1
             margin-right: 1rem
     .date
         color: color-grey-2
     .summary
         color: color-grey-1
         padding-left: 2rem
         padding-top: 0.7rem
         padding-bottom: 0.7rem
         font-style: italic
